import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import './AddInterview.css';
import Sidebar from '../sidebar/Sidebar';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FlashMessage } from '../flashmessage/FlashMessage';
import useSession from "../session/useSession";

const InterviewForm = () => {
  const [validationErrors, setValidationErrors] = useState({
    round: '',
    feedback: '',
    interview_type: '',
    guest: '',
    start_time: '',
    end_time: '',
    call_details: '',
    description: '',
    status: '',
    ctb: '',
    interviewer_name: '',
    interviewer_designation: '',
    linked_in_url: '',
  });
  useSession();
  const history = useHistory();
  const { sub_id } = useParams();
  const interview_choices = [
    { id: "video_call", value: "Video Call" },
    { id: "telephonic", value: "Voice Call" }
  ];
  const interview_status_choice = [
    { id: "failed", value: "Failed" },
    { id: "cancelled", value: "Cancelled" },
    { id: "scheduled", value: "Scheduled" },
    { id: "next_round", value: "Next Round" },
    { id: "feedback_due", value: "Feedback Due" },
  ];
  const [flashMessage, setFlashMessage] = useState(null);
  const [flashMessageType, setFlashMessageType] = useState(null);
  const [PrimaryMarketerList, setPrimaryMarketerList] = useState([]);
  const [SupervisorList, setSupervisorList] = useState([]);
  const [formData, setFormData] = useState({
    round: 0,
    feedback: '',
    interview_type: '',
    guest: [],
    start_time: '',
    end_time: '',
    call_details: '',
    description: '',
    attachment_link: '',
    calendar_id: '',
    submission: parseInt(sub_id),
    ctb: '',
    status: '',
    interviewer_name: '',
    interviewer_designation: '',
    linked_in_url: '',
  });
  const displayFlashMessage = (message, type) => {
    setFlashMessage(message);
    setFlashMessageType(type);
    setTimeout(() => {
      setFlashMessage(null);
      setFlashMessageType(null);
    }, 1000);
  };
  const set_map = {
    showDashboard: false,
    showCandidate: false,
    showMarketing: true,
    showResume: false,
    openMy: false,
    openGlobal: false,
    openLogs: false,
    openAdmin: false,
    openCandidateTable: false,
    openSubmission: true,
    openInterview: false,
    openTest: false,
    openRequirements: false,
  };

  const handleMarketerChange = (e) => {
    const { name, options } = e.target;
    const selectedValues = Array.from(options)
      .filter((option) => option.selected)
      .map((option) => parseInt(option.value));

    setFormData((prevData) => ({
      ...prevData,
      guest: selectedValues,
    }));
  };
  const handleSupervisorChange = (e) => {
    const { name, value } = e.target;

    validateField(name, value);
    setFormData((prevData) => ({
      ...prevData,
      ctb: parseInt(value),
    }));
  }

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    let parsedValue = value;

    if (name === 'start_time' || name === 'end_time') {
      parsedValue = value ? new Date(value + 'Z') : null;
    }

    if (name === 'start_time' && parsedValue && formData.end_time && parsedValue >= formData.end_time) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        start_time: 'Start Time should be before End Time',
      }));
    } else if (name === 'end_time' && parsedValue && formData.start_time && parsedValue <= formData.start_time) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        end_time: 'End Time should be after Start Time',
      }));
    } else {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        start_time: '',
        end_time: '',
      }));
    }
    setFormData((prevData) => ({ ...prevData, [name]: parsedValue }));
  };

  const jsonData = useSelector((state) => state.user);
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted && jsonData) {
      const apiUrlUsers = `${process.env.REACT_APP_API_URL}/employee/all_user_list/`;

      axios.get(apiUrlUsers, {
        headers: {
          Authorization: `Token ${jsonData?.result.token}`,
        }
      }).then(response => {
        console.log(response.data);
        setPrimaryMarketerList(response.data.results.marketers);
      }).catch(error => {
        console.error('Error fetching data:', error);
      });

      const apiUrlSupervisors = `${process.env.REACT_APP_API_URL}/marketing/supervisor/`;

      axios.get(apiUrlSupervisors, {
        headers: {
          Authorization: `Token ${jsonData?.result.token}`,
        }
      }).then(response => {
        console.log(response.data);
        setSupervisorList(response.data.result);
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  }, [history, jsonData, isMounted]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const parsedValue = name === 'round' ? parseInt(value, 10) : value;
    validateField(name, parsedValue);
    setFormData((prevData) => ({ ...prevData, [name]: parsedValue }));
  };
  const validateField = (name, value) => {
    switch (name) {
      case 'interview_type':
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          interview_type: value ? '' : 'Please select an interview type',
        }));
        break;

      case 'start_time':
      case 'end_time':
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: value ? '' : 'Please select a valid date and time',
        }));
        break;
      case 'status':
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          status: value ? '' : 'Please select an interview status',
        }));
        break;
      case 'ctb':
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          ctb: value ? '' : 'Please select a supervisor',
        }));
        break;
      default:
        break;
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    validateAllFields();

    if (Object.values(validationErrors).some((error) => error !== '')) {
      displayFlashMessage('Enter the details correctly');
      return;
    }
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/marketing/interview/`, formData,
        {
          headers: {
            Authorization: `Token ${jsonData.result.token}`,
          },
        }
      );
      displayFlashMessage('Interview Added Successfully', 'success');
      setFormData({
        feedback: '',
        interview_type: '',
        start_time: '',
        end_time: '',
        guest: [],
        ctb: '',
        call_details: '',
        description: '',
        status: '',
        interviewer_name: '',
        interviewer_designation: '',
        linked_in_url: '',
      });
      setTimeout(() => {
        history.push(`/AddInterview/Interviews`);
      }, 1000);
    } catch (error) {
      console.error('Error submitting form:', error.message);
    }
  };
  const validateAllFields = () => {
    validateField('interview_type', formData.interview_type);
    validateField('start_time', formData.start_time);
    validateField('end_time', formData.end_time);
    validateField('status', formData.status);
    validateField('ctb', formData.ctb)
  };
  return (
    <div className="ml-56 bg-[#171111]">
      <div className="sidebar-addinterview">
        <Sidebar set_map={set_map} />
      </div>
      <div className="pt-10">
        {flashMessage && (
          <FlashMessage message={flashMessage} type={flashMessageType} />
        )}
        <div className="bg-[#292256] rounded-l-full ml-10">
          <h1 className="text-2xl text-white font-semibold pl-6 py-2">ADD INTERVIEW DETAILS</h1>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-grid mx-16 mt-10">
            <div className="form-group">
              <label className='text-white text-xl'>Interview Type:</label>
              <select className='bg-[#171111] text-white' name="interview_type" value={formData.interview_type} onChange={handleChange}>
                <option value="">Select Interview Type</option>
                {interview_choices.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.value}
                  </option>
                ))}
              </select>
              <div style={{ color: 'red', fontSize: '14px' }}>{validationErrors.interview_type}</div>
            </div>
            <div className="form-group mt-[0.5px]">
              <label className='text-white text-xl'>Supervisor:</label>
              <select className='bg-[#171111] text-white'
                id="ctb"
                name="ctb"
                onChange={handleSupervisorChange}
              >
                <option value="">Select a Supervisor</option>
                {SupervisorList?.map((supervisor) => (
                  <option key={supervisor.id} value={supervisor.id}>
                    {supervisor.name}
                  </option>
                ))}
              </select>
              <div style={{ color: 'red', fontSize: '14px' }}>{validationErrors.ctb}</div>
            </div>
            <div className="form-group">
              <label className='text-white text-xl'>Guest:</label>
              <select className='bg-[#171111] text-white'
                id="guest"
                name="guest"
                onChange={handleMarketerChange}
              // multiple
              >
                <option value="" >Select a guest</option>
                {PrimaryMarketerList?.map((marketer) => (
                  <option key={marketer.id} value={marketer.id}>
                    {marketer.full_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label className='text-white text-xl'>Calling Details:</label>
              <textarea className='bg-[#171111] text-white h-[42px]'
                name="call_details"
                value={formData.call_details}
                onChange={handleChange}
              />
              <div style={{ color: 'red', fontSize: '14px' }}>{validationErrors.call_details}</div>
            </div>
            <div className="form-group">
              <label className='text-white text-xl'>Start Time:</label>
              <input className='bg-[#171111] text-white' type="datetime-local" name="start_time" value={formData.start_time ? formData.start_time.toISOString().slice(0, 16) : ''} onChange={handleDateChange} />
              <div style={{ color: 'red', fontSize: '14px' }}>{validationErrors.start_time}</div>
            </div>
            <div className="form-group">
              <label className='text-white text-xl'>End Time:</label>
              <input className='bg-[#171111] text-white' type="datetime-local" name="end_time" value={formData.end_time ? formData.end_time.toISOString().slice(0, 16) : ''} onChange={handleDateChange} />
              <div style={{ color: 'red', fontSize: '14px' }}>{validationErrors.end_time}</div>
            </div>

            <div className="form-group">
              <label className='text-white text-xl'>Additional Details:</label>
              <textarea className='bg-[#171111] text-white h-[42px]'
                name="description"
                value={formData.description}
                onChange={handleChange} />
            </div>
            <div className="form-group">
              <label className='text-white text-xl'>Status:</label>
              <select className='bg-[#171111] text-white' name="status" value={formData.status} onChange={handleChange}>
                <option value="">Select Interview Status</option>
                {interview_status_choice.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.value}
                  </option>
                ))}
              </select>
              <div style={{ color: 'red', fontSize: '14px' }}>{validationErrors.status}</div>
            </div>
            <div className="form-group">
              <label>interviewer Name:</label>
              <input type='textfield'
                name="interviewer_name"
                value={formData.interviewer_name}
                onChange={handleChange}
              />
              <div style={{ color: 'red', fontSize: '14px' }}>{validationErrors.interviewer_name}</div>
            </div>
            <div className="form-group">
              <label>interviewer Designation:</label>
              <input type='textfield'
                name="interviewer_designation"
                value={formData.interviewer_designation}
                onChange={handleChange}
              />
              <div style={{ color: 'red', fontSize: '14px' }}>{validationErrors.interviewer_designation}</div>
            </div>
            <div className="form-group">
              <label>Linkedin URL:</label>
              <input type='textfield'
                name="linked_in_url"
                value={formData.linked_in_url}
                onChange={handleChange}
              />
              <div style={{ color: 'red', fontSize: '14px' }}>{validationErrors.linked_in_url}</div>
            </div>

          </div>
          <button type="submit" className='ml-16 mb-4 bg-[#b4a4f5] text-slate-800 font-medium hover:bg-[#6c48fd] hover:text-white'>Submit</button>
        </form>
      </div>
    </div>
  );
};

export default InterviewForm;
