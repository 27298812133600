import React, { useState, useEffect } from "react";
import axios from 'axios';
import InterviewDetailsPage from "./InterviewDetailsPage";
import { useSelector } from 'react-redux';
import { BiCaretDown } from "react-icons/bi";
import { useHistory } from 'react-router-dom';
import RedirectIcon from "../assets/RedirectIcon.svg";

import {
  FcManager,
  FcBusinessman,
  FcDepartment,
  FcHome,
  FcOvertime,
  FcGraduationCap,
  FcReadingEbook,
  FcBriefcase,
  FcCustomerSupport,
  FcSlrBackSide,
  FcCheckmark,
  FcClock,
  FcCancel,
} from "react-icons/fc";
import { FaLinkedin } from "react-icons/fa";
import { AiFillSchedule } from "react-icons/ai";
import { MdNextPlan, MdOutlineSmsFailed } from "react-icons/md";
import { BsReverseLayoutTextWindowReverse } from "react-icons/bs";
import "./Interviews.css";
import Status from "./Status";
import Sidebar from "../sidebar/Sidebar";
import useSession from "../session/useSession";
import ConsultantFilter from "../submissions/ConsultantFilter";
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import zIndex from "@mui/material/styles/zIndex";
import {
  Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";
const Interviews = () => {
  useSession();
  const [selectedStatusValue, setSelectedStatusValue] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [interviewCards, setInterviewCards] = useState([]);
  const jsonData = useSelector((state) => state.user);
  const [isMounted, setIsMounted] = useState(false);
  const [endIndex, setEndIndex] = useState(0);
  const [selectedStatus, setStatusValue] = useState("");
  const [selectedConsultant, setSelectedConsultant] = useState(null);
  // const [interviewerName,SetInterviewerName]=useState("NA");
  // const [interviewerDesignation,SetInterviewerDesignation]=useState("NA");
  // const [interviewerLinkedin,setInterviewerLinkedin]=useState("");
  const [consKey, setConsKey] = useState(0);
  const history = useHistory();
  const location = useLocation();
  const set_map = {
    showDashboard: false,
    showCandidate: false,
    showMarketing: true,
    showResume: false,
    openMy: false,
    openGlobal: false,
    openLogs: false,
    openAdmin: false,
    openCandidateTable: false,
    openSubmission: false,
    openInterview: true,
    openTest: false,
    openRequirements: false,
  };
  const filters = [
    { id: "failed", value: "Failed" },
    { id: "feedback_due", value: "Pending" },
    { id: "cleared", value: "Cleared" },
    { id: "scheduled", value: "Scheduled" },
    { id: "next_round", value: "Next Round" }
  ];
  const statusMappings = {
    cleared: 'Cleared',
    failed: 'Failed',
    feedback_due: 'Feedback Due',
    scheduled: 'Scheduled',
    next_round: 'Next Round',
    cancelled: 'Cancelled',
  };
  const roundMappings = {
    // screening: 'Screening',
    video_call: 'VideoCall',
    telephonic: 'VoiceCall',

  }
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const itemsPerPage = 10;
  useEffect(() => {
    if (isMounted && jsonData) {
      const apiUrlConsultants = `${process.env.REACT_APP_API_URL}/marketing/interview`;
      axios.get(apiUrlConsultants, {
        params: {
          page: currentPage,
          page_size: itemsPerPage,
          status: selectedStatus,
          search: searchTerm,
          consultant_id: selectedConsultant,
          start_date: location?.state?.start_date,
          end_date: location?.state?.end_date,
          scheduled: location?.state?.scheduled
        },
        headers: {
          Authorization: `Token ${jsonData?.result.token}`,
        }
      }).then(response => {
        setInterviewCards(response.data.results);
        console.log(response.data.results)
        setEndIndex(response.data.total);
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  }, [currentPage, jsonData, searchTerm, selectedConsultant, selectedStatus, isMounted]);


  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleStatusOptionClick = (option) => {
    setSelectedStatusValue(option.value);
    setIsStatusDropdownOpen(false);
    setStatusValue(option.id)
  };
  const clearFilter = () => {
    setConsKey(prevKey => prevKey + 1);
    setSelectedStatusValue("");
    setIsStatusDropdownOpen(false);
    // selectedConsultant("")
    setSelectedConsultant(null);
    setStatusValue("");
    setSearchTerm("");
    setCurrentPage(1);
  };
  const handleConsultantChange = () => {
    setCurrentPage(1);
  };
  const handleInterviewUpdate = (id) => {
    history.push(`/interviews/InterviewUpdate/${id}`);
  }
  const handleNextRound = (id) => {
    history.push(`/submission/AddInterview/${id}`);
  }
  const handleInterviewDetails = (id) => {
    history.push(`/interviews/InterviewDetailsPage/${id}`);
  }
  const handleGoToSubmission = (id) => {
    history.push(`/submissions/submission/${id}`);
  }
  console.log(interviewCards)
  return (
    <div className="interview-page">
      <div className="sidebar-interview">
        <Sidebar set_map={set_map} />
      </div>
      <div className="interview-page-container overflow-hidden">
        <div className="bg-[#292256] rounded-l-full 2xl:ml-14 lap-13:ml-8 mt-10">
          <h1 className="text-2xl text-white font-semibold pl-6 py-2">INTERVIEW</h1>
        </div>
        <div className="grid grid-cols-4 justify-items-center items-center gap-20 ml-[9.5rem]">
          {/* Search box item */}
          <div className="mr-40 flex flex-row items-center justify-center border-1 bg-[#292256] rounded-full" style={{ width: "250px", height: "40px" }}>
            <i className="fa-solid fa-magnifying-glass text-white ml-3 my-1"></i>
            <input
              type="text"
              placeholder="Search Interviews"
              className="h-8 my-1 border-0 bg-[#292256] rounded-full text-white"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value)
                setCurrentPage(1)
              }}
            />
          </div>

          <Status setCurrentPage={setCurrentPage} filters={filters} selectedStatusValue={selectedStatusValue} setSelectedStatusValue={setSelectedStatusValue} handleStatusOptionClick={handleStatusOptionClick} />
          <ConsultantFilter key={consKey} onSelect={setSelectedConsultant} onClearSearchTerm={clearFilter} onConsultantChange={handleConsultantChange} />
          <button
            className="bg-[#b4a4f5] text-slate-800 font-medium h-9 hover:bg-[#6c48fd] hover:text-white w-max  min-w-[110px] max-w-full font-sans mb-2"
            onClick={clearFilter}
          >Clear</button>

        </div>
        <div className="bg-[#171111]">
          <div className="grid grid-flow-col grid-cols-5 content-end space-x-6 ml-[2.5rem]">
            <div className="flex flex-row items-center space-x-2 ml-[1rem]">
              <i class="fa-regular fa-comments text-white"></i>
              <p className="text-white font-bold text-lg font-sans">Interview Info</p>
            </div>
            <div className="flex flex-row items-center space-x-2">
              <i class="fa-solid fa-circle-info text-white"></i>
              <p className="text-white font-bold text-lg font-sans">Consultant Info</p>
            </div>
            <div className="flex flex-row items-center space-x-2">
              <i class="fa-regular fa-building text-white"></i>
              <p className="text-white font-bold text-lg font-sans">Vendor</p>
            </div>
            <div className="flex flex-row items-center space-x-2">
              <i class="fa-solid fa-building text-white"></i>
              <p className="text-white font-bold text-lg font-sans">Client</p>
            </div>
            <div className="flex flex-row items-center space-x-2">
              <i class="fa-regular fa-window-restore text-white"></i>
              <p className="text-white font-bold text-lg font-sans">More Info</p>
            </div>
          </div>
        </div>
        {/* <Card className="mx-14 my-4 bg-[#292256] text-white"> */}
        <CardBody className="min-h-[500px] w-full">
          <ul>
            {interviewCards?.map((interviewCard) => (
              <li key={interviewCard.id} className="interviews-box mx-8 my-4 rounded-2xl" >
                <div className="grid grid-flow-col grid-cols-5 rounded-2xl mx-4 my-2 content-around items-start gap-14">
                  <div className=" flex flex-col justify-center space-y-4 w-[25%]">
                    <span className="text-white text-base font-sans h-[30px] w-[180px] " title="Job Description">
                      {interviewCard?.job_title}
                    </span>
                    <span className="w-[180px]" >
                      <p className="text-white text-base font-sans " title="Interview Status">{statusMappings[interviewCard.status] || interviewCard.status}</p>
                    </span>
                    <span className="w-[100px] overflow-hidden">
                      <p className="text-white text-base font-sans truncate" title="Start time">
                        {interviewCard?.start_time}
                      </p>
                    </span>

                  </div>

                  <div className=" flex flex-col justify-center space-y-4 w-[25%]">
                    <span className="text-white text-base font-sans h-[30px] w-[180px] " title="Consultant">
                      {interviewCard.consultant_name}
                    </span>
                    <span className="w-[180px]" >
                      <p className="text-white text-base font-sans " title="Supervisor">{interviewCard.ctb_name}</p>
                    </span>
                    <span className="w-[180px]">
                      <p className="text-white text-base font-sans " title="Marketer">{interviewCard?.marketer_name}</p>
                    </span>
                  </div>
                  {/* 
                  <div className="flex flex-col justify-around space-y-[55px] w-[25%] ml-4">
                    <span className=" w-[150px] h-[30px]">
                      <p className="text-white text-base font-sans" title="Consultant">
                        {interviewCard.consultant_name}
                      </p>
                    </span>
                    <span className=" w-[150px] h-[30px]">
                      <p className="text-white text-base font-sans" title="Supervisor">
                        {interviewCard.ctb_name}
                      </p>
                    </span>
                    <span className="flex flex-row items-center w-[150px] relative right-4 space-x-1">
                      <i class="fa-regular fa-user text-white"></i>
                      <p className='text-white text-base font-sans' title="Marketer">{interviewCard?.marketer_name}</p>
                    </span>
                  </div>
                   */}

                  <div className="flex flex-col  space-y-[51px] w-[95%] ml-[1.75rem]">
                    <span className="w-[150px] h-[25px]" title="Vendor">
                      <p className='text-white text-base font-sans'>{interviewCard?.company_name}</p>
                    </span>
                    <div className="flex gap-3 justify-center align-items-center w-[200px]">
                      <div className="flex bg-indigo-300 rounded-full px-2 items-center  h-[45px] w-[45px]">
                        <img
                          loading="lazy"
                          src={RedirectIcon}
                          className="w-full aspect-square h-[30px]"
                          title="Go to Submission"
                          onClick={() => handleGoToSubmission(interviewCard.submission_id)}
                        />
                      </div>
                      <button className="bg-[#b4a4f5] mt-0 text-slate-800 h-9 hover:bg-[#6c48fd]  hover:text-white w-max font-sans min-w-[100px] max-w-full" disabled={!(jsonData?.result.roles.some(role => ["admin", "superadmin"].includes(role))
                        || interviewCard?.marketer_id === jsonData?.result.id)} onClick={() => handleInterviewUpdate(interviewCard.id)}>
                        Edit
                      </button>
                    </div>
                  </div>

                  <div className="flex flex-col justify-around space-y-[55px] w-[25%] ml-[2.75rem]">
                    <span className="w-[150px] h-[25px]" title="Client">
                      <p className='text-white font-sans text-base'>{interviewCard?.client}</p>
                    </span>

                    <button className="bg-[#b4a4f5] text-slate-800 font-medium h-9 hover:bg-[#6c48fd] hover:text-white w-max min-w-[130px] max-w-full font-sans" disabled={!(jsonData?.result.roles.some(role => ["admin", "superadmin"].includes(role))
                      || interviewCard?.marketer_id === jsonData?.result.id)} onClick={() => handleNextRound(interviewCard.submission_id)}>
                      Add Next Round
                    </button>
                  </div>

                  <div className="flex flex-col justify-around space-y-[55px] ml-[2.5rem]">
                    <div className="flex flex-row justify-items-center justify-around">
                      <span className="text-white w-[150px] h-[25px] overflow-hidden whitespace-nowrap text-ellipsis" title="Interviewer Details">
                        <a href={interviewCard.linked_in_url} target="_blank" rel="noopener noreferrer" className="">
                          {interviewCard.interviewer_name ? interviewCard.interviewer_name : "NA"}
                        </a>
                      </span>
                      <span className="rounded-full text-white" title="Current Round">
                        {interviewCard?.round}
                      </span>
                    </div>
                    <button className="bg-[#b4a4f5] text-slate-800 font-medium h-9 hover:bg-[#6c48fd] hover:text-white w-max  min-w-[110px] max-w-full font-sans" disabled={!(jsonData?.result.roles.some(role => ["admin", "superadmin"].includes(role))
                      || interviewCard?.marketer_id === jsonData?.result.id)} onClick={() => handleInterviewDetails(interviewCard.id)}>
                      Show Details
                    </button>
                    {/* <button className="bg-[#b4a4f5] text-slate-800 font-medium h-9 hover:bg-[#6c48fd] hover:text-white w-max  min-w-[110px] max-w-full font-sans"  onClick={() => handleGoToSubmission(interviewCard.submission_id)}>
                        Go to Submission 
                      </button> */}
                  </div>

                </div>

              </li>
            ))}

          </ul>
          <div className="flex flex-row justify-end items-center space-x-2 mb-4 right-14">
            <button
              className="bg-[#171111] text-white hover:bg-[#171111] disabled:bg-[#171111] text-xl"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <FaArrowLeft />
            </button>
            <span>
              <p className="text-white text-2xl pt-2">{currentPage}</p>
            </span>
            <button
              className="bg-[#171111] hover:bg-[#171111] disabled:bg-[#171111] text-white text-xl"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={endIndex <= (itemsPerPage * (currentPage - 1)) + interviewCards.length}
            >
              <FaArrowRight />
            </button>
          </div>
        </CardBody>
        {/* </Card> */}
      </div>
    </div>
  );
};

export default Interviews;
